import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ChallengePage, MainPage, RegisterPage } from './pages';
import { UserState } from './contexts/user/UserState';
import { ApiState } from './contexts/api/ApiState';
import ScrollToTop from './utils/ScrollToTop';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

function App() {
  const excelYear = "2024";
  const currentRefreshSavedYear = localStorage.getItem("refreshSavedYear");
  if (!currentRefreshSavedYear || currentRefreshSavedYear !== excelYear) {
    localStorage.removeItem("refreshSavedYear");
    localStorage.removeItem("refreshToken");
    localStorage.setItem("refreshSavedYear", excelYear);
    window.location.reload();
    console.log("A New year, A New Excel");
  }

  return (
    <div className="App">
      <ApiState>
        <UserState>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/register" element={
                <ProtectedRoute>
                  <RegisterPage />
                </ProtectedRoute>
              } />

              <Route path="/challenge/*" element={
                <ChallengePage />
              } />

              <Route path='*' element={<Navigate to={'/'} replace={true} />} />


            </Routes>
          </BrowserRouter>
        </UserState>
      </ApiState>
    </div >
  );
}

export default App;
