import { useCallback, useEffect, useState } from 'react';
import { ApiContext } from './ApiContext';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import axios from 'axios';

export function ApiState({ children }) {
	const accBaseUrl = process.env.REACT_APP_ACC_BACKEND_BASE_URL;
	const cyberQuestBaseUrl = process.env.REACT_APP_CYBERQUEST_BACKEND_BASE_URL;

	if (!process.env.REACT_APP_AUTH_REDIR_URL) {
		throw new Error('REACT_APP_AUTH_REDIR_URL is undefined');
	}

	if (!accBaseUrl) {
		throw new Error('REACT_APP_ACC_BACKEND_BASE_URL is undefined');
	}
	if (!cyberQuestBaseUrl) {
		throw new Error('REACT_APP_CYBERQUEST_BACKEND_BASE_URL is undefined');
	}

	const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', '');
	const [accessToken, setAccessToken] = useLocalStorage('accessToken', '');
	const [checkingLogginIn, setCheckingLogginIn] = useState(true);


	function checkRefreshFromUrl() {
		const currUrl = new URL(window.location.href);
		let newRefreshToken = currUrl.searchParams.get('refreshToken');
		if (newRefreshToken) {
			setRefreshToken(newRefreshToken);
			currUrl.searchParams.delete('refreshToken');
			window.history.replaceState({}, '', currUrl.toString());
		}

		setCheckingLogginIn(false);
	}

	useEffect(() => {
		checkRefreshFromUrl();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const axiosConfig = {
		timeout: 10000,
		headers: {
			'Content-type': 'application/json',
		},
	};
	//Axios with Access Token
	const axiosAccPrivate = axios.create({
		...axiosConfig,
		baseURL: accBaseUrl,
	});

	//Axios without Access Token
	const axiosAccPublic = axios.create({
		...axiosConfig,
		baseURL: accBaseUrl,
	});

	//Axios with Access Token
	const axiosCyberQuestPrivate = axios.create({
		...axiosConfig,
		baseURL: cyberQuestBaseUrl,
	});

	//Axios without Access Token
	const axiosCyberQuestPublic = axios.create({
		...axiosConfig,
		baseURL: cyberQuestBaseUrl,
	});

	async function refreshTheAccessToken() {
		if (!refreshToken) {
			return '';
		}

		const response = await axiosAccPublic.post(
			'/api/Auth/refresh',
			JSON.stringify({ refreshToken: refreshToken })
		);

		const { accessToken } = response.data;
		setAccessToken(accessToken);
		return accessToken;
	}
	const refreshAccessToken = useCallback(refreshTheAccessToken, [
		refreshToken,
		axiosAccPublic,
		setAccessToken,
	]);

	useEffect(() => {
		refreshAccessToken();
	}, [refreshToken, axiosAccPublic, setAccessToken, refreshAccessToken]);

	const attachAccessToken = (config) => {
		if (accessToken && accessToken.length > 0) {
			config.headers['Authorization'] = 'Bearer ' + accessToken;
			return config;
		} else {
			//Clear authorization
			config.headers['Authorization'] = '';
		}
		return config;
	};

	axiosAccPrivate.interceptors.request.clear();
	axiosCyberQuestPrivate.interceptors.request.clear();

	/**
	 * Attach Access Token to every request
	 */
	axiosAccPrivate.interceptors.request.use(attachAccessToken);
	axiosCyberQuestPrivate.interceptors.request.use(attachAccessToken);

	axiosAccPrivate.interceptors.response.clear();
	axiosCyberQuestPrivate.interceptors.response.clear();

	const retryWithAt = [
		(res) => {
			return res;
		},

		async (err) => {
			const originalConfig = err.config;

			// Temporary logging
			// console.log({
			// 	err,
			// 	keys: Object.keys(err),
			// });
			if (
				!originalConfig._retry &&
				(err.response?.status === 401 || // For expired token
					err?.code === 'ECONNABORTED') // For cold start timeouts
			) {
				console.log('Token Expired, Retrying');
				originalConfig._retry = true;

				try {
					const newAccessToken = await refreshAccessToken();
					axiosAccPrivate.defaults.headers.common['Authorization'] =
						'Bearer ' + newAccessToken;
					originalConfig.headers['Authorization'] =
						'Bearer ' + newAccessToken;
					return await axios(originalConfig);
				} catch (_error) {
					return Promise.reject(_error);
				}
			}
			return Promise.reject(err);
		},
	];

	/**
	 * Refresh Access Token on expiry
	 */
	axiosAccPrivate.interceptors.response.use(...retryWithAt);
	axiosCyberQuestPrivate.interceptors.response.use(...retryWithAt);

	return (
		<ApiContext.Provider
			value={{
				accessToken,
				refreshToken,
				setAccessToken,
				setRefreshToken,
				axiosAccPrivate,
				axiosAccPublic,
				axiosCyberQuestPrivate,
				axiosCyberQuestPublic,
				checkingLogginIn,
			}}
		>
			{children}
		</ApiContext.Provider>
	);
}
